import React from 'react'
import LoginButton from './LoginButton'
import { connect } from 'react-redux'

function OAuthBar({ config }) {
  const apiURL = process.env.REACT_APP_DOMAIN
    ? `https://${process.env.REACT_APP_DOMAIN}/api`
    : ''
  if (!config.providers) return null

  const providers = ['twitter', 'google', 'facebook'].filter(key => config.providers[key])

  const signIn = provider => {
    window.open(`${apiURL}/oauth/${provider}`, '_self')
  }

  return (
    <div className='oauth-bar'>
      {providers.map(provider => (
        <LoginButton key={provider} provider={provider} onClick={() => signIn(provider)} />
      ))}
    </div>
  )
}

const mapStateToProps = ({ auth, configuration }) => ({ auth, config: configuration.public })
export default connect(mapStateToProps)(OAuthBar)
