/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import Router from './components/Router/Router'
import { actions } from 'modules/Auth/auth.redux'
import { routes } from './routes'
import { counterActions } from 'modules/Counter/counter.redux'
import { configurationActions } from 'modules/Configuration/configuration.redux'

function App(props) {
  useEffect(() => {
    props.loadConfiguration()
    props.checkLogin()
    props.incrementCounter('visits')
  }, [])

  return (
    <Fragment>
      <Router routes={routes} />
    </Fragment>
  )
}

const mapStateToProps = state => ({ campaigns: state.campaign.list })
const mapDispatchToProps = {
  checkLogin: actions.checkLoginRequest,
  incrementCounter: counterActions.incrementCounterRequest,
  loadConfiguration: configurationActions.publicLoadConfigurationRequest
}
const withConnect = connect(mapStateToProps, mapDispatchToProps)
export default withConnect(App)
